<template>
  <div v-if="isLoaded" class="account-settings-container">
    <div class="career-status">
      <h3 class="setting-header"><b>Your Career Status</b></h3>
      <p>
        The VioLegacy functions are customized for your current career status.
        You can always change it here and your data for each status will be
        saved.
      </p>
      <label class="checkbox-template" id="junior-btn" :enabled="userHasAccess"
        @click="showAlert">I'm looking for jobs.
        <p v-if="!userHasAccess">
          (Access Denied. Verified NYU email address required.)
        </p>
        <input
          type="radio"
          name="radio"
          :value="false"
          v-model="endStatus"
          :disabled="!userHasAccess"
        />
        <span class="checkmark"></span>
      </label>
      <label class="checkbox-template" id="senior-btn" @click="showAlert">
        I'm providing jobs.
        <input type="radio" name="radio" :value="true" v-model="endStatus" />
        <span class="checkmark"></span>
      </label>
    </div>
    <div class="account-management">
      <h3 class="setting-header">Account Management</h3>
      <button
        v-if="userIsAdmin"
        class="account-management-btn"
        @click="toAdminPage()"
      >
        To Admin Page
      </button>
      <button
        v-if="!userVerified"
        class="account-management-btn"
        @click="sendVerification()"
      >
        Email Verification
      </button>
      <button
        class="account-management-btn"
        @click="isChangingEmail = !isChangingEmail;"
      >
        Change Email Address
      </button>
      <div class="credentials-change-block" v-if="isChangingEmail">
        <h4>
          Password:
          <input
            type="password"
            id="password-input"
            name="password"
            v-model="password"
          />
        </h4>
        <h4>
          New Email Adress:
          <input
            type="email"
            id="new-email-input"
            name="new-email"
            v-model="newEmail"
          />
        </h4>
        <h4>
          Confirm New Email Adress:
          <input
            type="email"
            id="confirm-new-email-input"
            name="confirm-new-email"
            v-model="newEmail2"
          />
        </h4>
        <button class="confirmChangeBtn" @click ="handleChangeEmail()">Confirm Change</button>
      </div>
      <button
        class="account-management-btn"
        @click="isChangingPassword = !isChangingPassword;"
      >
        Change Password
      </button>
      <div class="credentials-change-block" v-if="isChangingPassword">
        <h4>
          Old Password:
          <input
            type="password"
            id="old-password-input"
            name="old-password"
            v-model="oldPassword"
          />
        </h4>
        <h4>
          New Password:
          <input
            type="password"
            id="new-password-input"
            name="new-password"
            v-model="newPassword"
          />
        </h4>
        <h4>
          Confirm New Password:
          <input
            type="password"
            id="confirm-new-password-input"
            name="confirm-new-password"
            v-model="newPassword2"
          />
        </h4>
        <button class="confirmChangeBtn" @click ="handleChangePassword()">Confirm Change</button>
      </div>
      <button
        class="account-management-btn"
        @click="isUploadingResume = !isUploadingResume;"
      >
        Upload Resume
      </button>
      <div class="credentials-change-block" v-if="isUploadingResume">
        <input
          class="input-file"
          type="file"
          id="input-resume"
          ref="resume"
          @change="changeTag"
        />
        <label
          class="input-file-label"
          for="input-resume"
        >
          <ion-icon class="label-icon" name="cloud-upload-outline"></ion-icon>
          <p v-if="resumeTag">
            {{ resumeTag }}
          </p>
          <p v-else>Choose a file...</p>
        </label>
        <button class="confirmChangeBtn" @click ="handleUpload('Resumes');">Confirm Upload</button>
      </div>

      <button
        class="account-management-btn"
        @click="isUploadingProfilePic = !isUploadingProfilePic;"
      >
        Upload Profile Picture
      </button>
      <div class="credentials-change-block" v-if="isUploadingProfilePic">
        <input
          class="input-file"
          type="file"
          id="input-avatar"
          ref="profilePic"
          @change="changeTag"
        />
        <label
          class="input-file-label"
          for="input-avatar"
        >
          <ion-icon class="label-icon" name="cloud-upload-outline"></ion-icon>
          <p v-if="profilePicTag">
            {{ profilePicTag }}
          </p>
          <p v-else>Choose a picture...</p>
        </label>
        <button class="confirmChangeBtn" @click ="handleUpload('Images');">Confirm Upload</button>
      </div>
    </div>
    <div class="report">
      <h3 class="setting-header">Report</h3>
      <button class="report-btn" @click="handleReport()">Report</button>
    </div>
  </div>
</template>

<script>
import getUser from "@/composables/getUser";
import getProfile from "@/composables/Profile/getProfile";
import useSendVerification from "@/composables/Verification/useSendVerification";
import getUserAccessStatus from "@/composables/Verification/getUserAccessStatus";
import useChangePassword from "@/composables/AccountSettings/changePassword";
import useChangeEmail from "@/composables/AccountSettings/changeEmail";
import uploadFile from "@/composables/Storage/uploadFile";
import deleteFile from "@/composables/Storage/deleteFile";
import { useRouter } from "vue-router";
import { ref, watchEffect, onMounted } from "vue";
import {createToast} from "mosha-vue-toastify";
import 'mosha-vue-toastify/dist/style.css';

export default {
  props: ["onSeniorEnd"],
  emits: ["change-end-status"],
  async setup(props, { emit }) {
    const { user } = getUser();
    const profile = ref(null);
    const router = useRouter();
    const { userVerified, userHasAccess } = getUserAccessStatus();
    const { USVerror, sendVerification } = useSendVerification();

    const isChangingPassword = ref(false);
    const oldPassword = ref("");
    const newPassword = ref("");
    const newPassword2 = ref("");
    const { UCPerror, changePassword } = useChangePassword();

    const isChangingEmail = ref(false);
    const password = ref("");
    const newEmail = ref("");
    const newEmail2 = ref("");
    const { UCEerror, changeEmail } = useChangeEmail();

    const isUploadingResume = ref(false);
    const isUploadingProfilePic = ref(false);
    const endStatus = ref(props.onSeniorEnd);
    const resume = ref(null);
    const profilePic = ref(null);
    const resumeTag = ref(null);
    const profilePicTag = ref(null);
    const userIsAdmin = ref(false);
    const isLoaded = ref(false);

    const tempStore = ref(null);

    onMounted(async () => {
      await getProfile(profile, user.value.uid);
      isLoaded.value = true;

      if (profile.value.isAdmin) {
        userIsAdmin.value = true;
      }

      watchEffect(() => {
        // console.log("endStatus changed to: " + endStatus.value);
        emit("change-end-status", endStatus.value);
      });
    });

    const changeSubmitAlert = () => {
      createToast({
        title: "Success!",
        description: 'Your changes are saved.'
      }, {
        type: "success"
      })
    }

    const handleChangePassword = async () => {
      changeSubmitAlert()
      if (newPassword.value != newPassword2.value) {
        alert("New passwords do not match");
      }
      else if (oldPassword.value == ""){
        alert("Please enter your old password.")
      }
      else if (newPassword.value == ""){
        alert("Please enter your new password.")
      }
      else{
        await changePassword(oldPassword.value, newPassword.value);
        oldPassword = ""
        newPassword = ""
        newPassword2 = ""
        isChangingPassword.value = ! isChangingPassword.value
        if (!UCPerror.value) {
            // console.log("finished");
            alert("Password changed successfully.");
          } else {
            alert(UCPerror.value);
          }
      }
    };

    const handleReport = () => {
      router.push({ name: "Report" });
    };

    const handleChangeEmail = async () => {
      changeSubmitAlert()
      if (newEmail.value != newEmail2.value) {
        alert("New email adresses do not match");
      }
      else if (password.value == ""){
        alert("Please enter your current password.")
      }
      else if (newEmail.value == ""){
        alert("Please enter your current email address.")
      }
      else{
        await changeEmail(password.value, newEmail.value);
        password.value = ""
        newEmail.value = ""
        newEmail2.value = ""
        isChangingEmail.value = !isChangingEmail.value
        if (!UCEerror.value) {
          alert("Email changed successfully.");
        } else {
          alert(UCEerror.value);
        }
      }
    };

    const handleUpload = async (type) => {
      try {
        if (type == "Resumes") {
          // console.log("resume");
          if (resume.value.files[0]) {
            await deleteFile(user.value.uid, type);
            await uploadFile(resume.value.files[0], resumeTag.value, user.value.uid, type, tempStore);
            isUploadingResume.value = !isUploadingResume.value;
            resume.value = null;
            resumeTag.value = null;
            changeSubmitAlert()
          }
          else{
            alert("Please choose a file.")
          }
        } else if (type == "Images") {
          // console.log("ProfilePic");
          if (isUploadingProfilePic.value) {
            await deleteFile(user.value.uid, type);
            await uploadFile(profilePic.value.files[0], profilePic.value.files[0].name, user.value.uid, type, tempStore);
            isUploadingProfilePic.value = ! isUploadingProfilePic.value;
            profilePic.value = null;
            profilePicTag.value = null;
            alert("Profile Picture uploaded successfully.");
          }
          else{
            alert("Please choose a picture.")
          }
        }
      } catch (err) {
        alert(err);
      }
    };

    const changeTag = () => {
      if(resume.value){
        if (resume.value.files[0]) {
          resumeTag.value = resume.value.files[0].name;
        }
      }
      if(profilePic.value){
        if (profilePic.value.files[0]) {
          profilePicTag.value = profilePic.value.files[0].name;
        }
      }
    };

    const toAdminPage = () => {
      router.push({ name: "AdminPage" });
    };

    return {
      isLoaded,
      isChangingPassword,
      userIsAdmin,
      oldPassword,
      newPassword,
      newPassword2,
      isChangingEmail,
      password,
      newEmail,
      newEmail2,
      resume,
      profilePic,
      resumeTag,
      profilePicTag,
      isUploadingResume,
      isUploadingProfilePic,
      changeSubmitAlert,
      handleChangePassword,
      handleChangeEmail,
      endStatus,
      handleUpload,
      handleReport,
      changeTag,
      userVerified,
      userHasAccess,
      sendVerification,
      toAdminPage,
    };
  },
  methods: {
    showAlert() {
      this.$swal.fire(
          'Switched!',
          'You changed your career status! The page content will be changed.',
          'success'
      )
    }
  }
};
</script>

<style scoped>
.account-settings-container {
  margin: 2vh 5vw 8vh 5vw;
  font-size: 150%;
  animation: fadein 1s;
}

.setting-header {
  font-weight: bold;
  margin: 2vh 0 3vh 0;
}

.career-status {
  padding: 1vh 0;
}

.career-status p {
  font-size: 75%;
  margin: 3vh 0;
}

.career-status-choice {
  display: flex;
}

.career-status-choice input {
  flex: 0 1 10%;
}

.career-status-choice p {
  flex: 1 0 auto;
}

.checkbox-template {
  font-size: 82%;
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.checkbox-template p {
  display: inline;
}

/* Hide the browser's default radio button */
.checkbox-template input {
  position: absolute;
  opacity: 0;
}

/* Create a custom radio button */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
}

.checkmark:disabled {
  background-color: white;
  cursor: none;
}

/* On mouse-over, add a grey background color */
.checkbox-template:hover input ~ .checkmark {
  background-color: white;
}

.checkbox-template input:disabled ~ .checkmark {
  background-color: white;
}

.checkbox-template input:disabled ~ .checkbox-template {
  cursor: none;
}

/* When the radio button is checked, add a blue background */
.checkbox-template input:checked ~ .checkmark {
  background-color: var(--purple);
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.checkbox-template input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.checkbox-template .checkmark:after {
  top: 9px;
  left: 9px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}

.account-management-btn {
  display: block;
  border: 2px var(--purple-l) solid;
  border-radius: 2vh;
  background-color: white;
  color: var(--purple-l);
  padding: 1vh 1vw;
  height: 6vh;
  width: 180px;
  margin: 3vh 0 1vh 0;
  transition: 0.3s ease-out;
}

.report-btn {
  display: block;
  border: 2px rgb(252, 91, 91) solid;
  border-radius: 2vh;
  background-color: white;
  color: rgb(252, 91, 91);
  padding: 1vh 1vw;
  height: 6vh;
  width: 180px;
  margin: 3vh 0 1vh 0;
}

.account-management-btn:hover {
  border-color: var(--purple);
  color: var(--purple);
  cursor: pointer;
}

.report-btn:hover {
  border: 2px red solid;
  color: white;
  background-color: red;
  cursor: pointer;
}

.credentials-change-block{
  display: inline-block;
  padding: 1vh 3vh;
  border: 2px var(--purple) dashed;
  border-radius: 2vh;
}

.credentials-change-block h4 {
  font-size: 75%;
  font-weight: 400;
  margin: 0.5vh 0;
}

.credentials-change-block h4 input {
  width: 20vw;
  border: none;
  border-bottom: 1px rgba(128, 128, 128, 0.5) solid;
  padding: 1vh 1vw;
}

.confirmChangeBtn {
  margin: 0.5vh 0;
  height: 36px;
  width: 140px;
  color: white;
  background-color: var(--purple);
  border: 2px var(--purple) solid;
  border-radius: 2vh;
}

.confirmChangeBtn:hover {
  border-color: #bf66d8;
  background-color: #bf66d8;
  cursor: pointer;
}

.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.input-file-label {
  margin: 1vh 1vh 0 0;
  padding: 0.5vh 0.5vw;
  font-size: 75%;
  text-align: center;
  color: var(--purple);
}

.input-file-label p {
  display: inline;
}

.input-file-label:hover {
  cursor: pointer;
  border-bottom: 1px #cca2d8 solid;
}

.label-icon {
  font-size: 150%;
  width: 30px;
  height: 30px;
  vertical-align: middle;
  fill: currentColor;
  margin-top: 0;
  margin-right: 8px;
}
</style>


